import React from 'react'
import loadable from '@loadable/component'

import { VidTitle } from 'components/blocks/PageHero/themes/Video/styles'
import { Level as TitleLevel } from 'components/base/Title'

import { Props } from './types'

const Title = loadable(() => import('components/base/Title').catch(() => () => null))

const PageHeroTitle: React.FC<Props> = ({
  children,
  parentTheme,
  ...props
}): React.ReactElement => {
  const Component = (parentTheme === 'video' && (
    <VidTitle {...props}>{children}</VidTitle>
  )) || (
    <Title level={TitleLevel.page} {...props}>
      {children}
    </Title>
  )

  return Component
}

export * from './types'

export default PageHeroTitle
